import { FC } from "react";
import { Redirect } from "react-router";

import { useSession } from "@/hooks/auth";
import { isServer } from "@/utils/detect";



export const Index: FC = function Index() {

  const { auth, loading, initializing } = useSession();

  if(isServer || loading || initializing) {
    return null;
  }

  if(auth) {
    return (
      <Redirect to="/feed" />
    );
  }

  return (
    <Redirect to="/auth/login" />
  );

}

export default Index;
